// 所属版块
const getSectionURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/getSectionList`

// 设置应用暂停时间
const pauseTimeURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/pauseSubjectServiceTime`

export {
  getSectionURL,
  pauseTimeURL
}
