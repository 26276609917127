import Vue from 'vue'
import { mapHelper } from 'common/utils'
import { getSectionURL } from './api'

const getSectionOps = async (params, hideDefaultOption) => {
  const { status, data } = await Vue.prototype.$axios.get(getSectionURL, params)
  if (status === 100) {
    const optionGroup = mapHelper.setOptionGroup(data, {
      defaultText: hideDefaultOption ? '' : '全部',
      parentIdKey: 'sectionParentid',
      labelKey: 'sectionName',
      keys: ['sectionType']
    })
    return optionGroup
  }
}

export {
  getSectionOps
}
